import React, {useCallback, useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {uploadCodReceipt, submitCodReceipts, uploadCodClick, deletePrevUploadedCodReceipt} from "./redux/stdCodPortalSlice";
//common components
import {AppState} from "../../config/redux/reducers";
import AppConstants from "../../constants";
import CustomDialog from "../../common/Dialog";
import TextBox from "../../common/TextBox";
import CustomSnackbar from "../../common/CustomSnackbar";

//assets
import attachIcon from "../../assets/icons/attach-key.svg";
import trashIcon from "../../assets/icons/trash.svg";
import downloadIcon from "../../assets/icons/download.svg";
import closeIcon from "../../assets/icons/circled-cancel.svg";

//Material UI Components
import {Grid, Typography, Button} from "@material-ui/core";

//styles
import {useStyles} from "./stdCodUploadReceipt.styles";

import {userLogout} from "../../Login/redux/loginSlice";
import {createPayloadToDeleteUploadedFile} from "../../mocks/codPortal/response.transforms";
import {dateWithReqFormat} from "../../utils/dateUtils";
import {FileStorageUpload, FileStorageSave} from "../../common/FileStorageOprations"
import * as yup from "yup";

interface StdCodUploadReceiptProps {
  open: boolean;
  hub: any;
  date: any;
  closePopup: () => void;
}

const StdCodUploadReceipt = (props: StdCodUploadReceiptProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {open, hub, date, closePopup} = props;
  const {
    stdDraftReceiptData,
    isStdFileUploaded,
    stdReceiptDataAfterUpload,
    stdUploadError,
    stdUploadErrorCode,
    stdReceiptsSubmittedFlag,
    stdReceiptDataAfterSubmit,
    isErrorOnStdUpload
  } = useSelector((state: AppState) => state.stdCodPortal);
  const dialogPaperProps = {
    classes: {
      root: classes.dialogPaperPropsRoot,
    },
  };
  const [receiptNo, setReceiptNo] = useState("");
  const [file, setFile] = useState({
    name: "",
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const snackbarType = useRef(AppConstants.SNACKBAR.TYPES.SUCCESS);
  const snackbarMessage = useRef("");
  const [tempReceipts, setTempReceipts] = useState([]);
  const [permanentReceipts, setPermanentReceipts] = useState([]);
  const [draftId, setDraftId] = useState("");
  const [enableAttachBtn, setEnableAttachBtn] = useState(false);
  const [maxSize, setMaxSize] =  useState(false);
  const [errorMsg, setErrorMsg] =  useState('');
  const maxSizeSnackbarType = useRef(AppConstants.SNACKBAR.TYPES.ERROR);
  const maxSizeMessage = AppConstants.COD_RECON_CONSTANTS.MAX_FILE_SIZE;
  const handleDialogClose = () => {
    closePopup();
    setReceiptNo("");
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setMaxSize(false);
  };



  const inputValidationSchema = yup.object({
    name: yup
      .string()
      .required(
        AppConstants.COD_RECON_CONSTANTS.VALIDATIONS.NAME.REQUIRED.msg
      )
      .matches(
        AppConstants.COD_RECON_CONSTANTS.VALIDATIONS.NAME.VALIDITY.value,
        AppConstants.COD_RECON_CONSTANTS.VALIDATIONS.NAME.VALIDITY.msg
      ),
  });

  const handleSnackbarExited = useCallback(() => {
    if (stdUploadErrorCode === AppConstants.COD_RECON_CONSTANTS.RESPONSE_CONSTANTS.ERROR_CODES.SESSION_TIMEOUT) {
      dispatch(userLogout());
    }
  }, [dispatch, stdUploadErrorCode]);

  const handleReceiptTextboxChange = (event: any) => {
    const receiptNumber = event.target.value;
    setReceiptNo(receiptNumber);
  };

  const validateInput = () => {
    const formData = {
      name: receiptNo,
    };
    inputValidationSchema.validate(formData, { abortEarly: false }).then(function() {
      setErrorMsg('')
      setEnableAttachBtn(true);
  }).catch(function (err) {
    setEnableAttachBtn(false);
    setErrorMsg( err.inner[0].message)
  })
  } 

  function handleUpload(selectedFile: any) {
    setFile(selectedFile);
    dispatch(uploadCodClick());
    let data = new FormData();
    if (selectedFile.size > AppConstants.COD_RECON_CONSTANTS.MAX_FILE_SIZE_ALLOWED) {
      setMaxSize(true);
      setEnableAttachBtn(false);
    } else {
      data.append("images", selectedFile);
      const request = JSON.stringify({
        id: stdDraftReceiptData && stdDraftReceiptData.id,
        receiptNumber: receiptNo,
      });
      data.append("request", request);
      dispatch(
        uploadCodReceipt({
          params: data,
        })
      );
    }
    //event.target.value = "";
    setReceiptNo("");
  }
  
  const deleteUploadedFile = (item: any, id: any, isTemp: boolean) => {
    const payload = createPayloadToDeleteUploadedFile(item, id, isTemp);
    dispatch(
      deletePrevUploadedCodReceipt({
        params: payload,
      })
    );
  };


  const downloadFile = (item: any) => {
    if (item) {
      saveAs(item.url, item.name);
    }
  };

  const handleSubmitReceipt = useCallback((draftId: any) => {
    const payload = {  id: draftId };
    dispatch(
      submitCodReceipts({
        params: payload,
      })
    );
    setReceiptNo("");
  }, [dispatch]);

  const getUploadedFiles = () => {
    return permanentReceipts?.length
      ? permanentReceipts.map((item: any, index: number) => (
          <Grid key={`${item.name.en} - ${index}`} className='uploadedFilePanel' item>
            <Grid item className='container'>
              <Grid item className='item'>
                <Grid container className='content'>
                  <Grid item>
                    <Typography className='fileName'>{item.name}</Typography>
                    <Typography className='fileTimestamp'>
                      Uploaded on {dateWithReqFormat(item.createdAt, AppConstants.DATE_FORMAT)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FileStorageSave icon={downloadIcon} item={item}/>
                    <img
                      src={trashIcon}
                      alt='delete'
                      className='icon delete'
                      onClick={() => deleteUploadedFile(item, draftId, false)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))
      : null;
  };

  const getPreviousUploads = () => {
    return (
      <>
        {permanentReceipts?.length ? (
          <div>
            <Typography className='panelTitle'>Previous Uploads</Typography>
            {getUploadedFiles()}
          </div>
        ) : (
          <Grid container className='defaultPanel'>
            <Grid item className='title'>
              <Typography className='content'>No previous uploads available</Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const renderTempReceiptsContainer = () => {
    return (
      tempReceipts?.length ?
      tempReceipts.map((item: any, index: number) => (
        <Grid key={`${item.name.en} - ${index}`} className={classes.tempContainer} item>
          <Grid container className="content">
            <Grid item xs={6}>
              <TextBox
                fullWidth
                disabled
                variant='outlined'
                value={`Receipt Number - ${item.receiptNumber}`}
                textBoxId='receiptNo'
                name='receiptNo'
                type='text'
              ></TextBox>
            </Grid>
            <Grid item xs={6} className="imgItem">
              <Typography className="name">{item.name}</Typography>
              <img
                src={closeIcon}
                alt='cancel' className="icon"
                onClick={() => deleteUploadedFile(item, draftId, true)} 
              />
            </Grid>
          </Grid>
        </Grid>
      )) : null
    );
  };

  const renderReceiptConfig = () => {
    return (
      <Grid container>
        <Grid className={classes.contentItem} item xs={6}>
          <Grid className='itemContainer' container>
            <Grid className='itemLabel' item>
              <Typography>Receipt No</Typography>
            </Grid>
            <Grid className='itemInput' item>
              <TextBox
                fullWidth
                variant='outlined'
                value={receiptNo}
                textBoxId='receiptNo'
                name='receiptNo'
                onChange={handleReceiptTextboxChange}
                type='text'
                placeholderText='Receipt Number'
              ></TextBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.attachItem}>
          <>
            <Button  className='secondary' variant='contained' onClick={validateInput}>
                   <FileStorageUpload
                      disabled={!enableAttachBtn}
                      accept=".jpg, .png, .jpeg, .csv, .xlsx, .xls, .pdf, .doc, .docx"
                      onChange={handleUpload}
                      icon={attachIcon}
                      Text={"Attach Receipt"}
                    />
            </Button>
          </>
        </Grid>
        {errorMsg && <Typography className={classes.errorMsg}>{errorMsg}</Typography>}
      </Grid>
    );
  };

  const getTitleContent = () => {
    return <Typography className={classes.uploadDialogTitle}>Cash Receipts</Typography>;
  };

  const getDetailsContent = () => {
    return (
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>Store Name : {hub}</Typography>
          </Grid>
          <Grid item>
            <Typography>Date : {date.startDate}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container>
            <Grid item xs={6} className='currentContainer'>
              {renderReceiptConfig()}
              {renderTempReceiptsContainer()}
            </Grid>
            <Grid container xs={6} className='prevContainer'>
              <Grid container className='receiptsPanel'>
                {getPreviousUploads()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const getUploadActions = () => {
    return (
      <Grid className={classes.uploadButtonsContainer} container>
        <Button className='dialogBtn secondary' variant='contained' onClick={() => handleDialogClose()}>
          {AppConstants.BUTTONS.CANCEL}
        </Button>
        <Button className='dialogBtn primary' variant='contained' onClick={() => handleSubmitReceipt(draftId)}>
          {AppConstants.BUTTONS.PROCEED}
        </Button>
      </Grid>
    );
  };

  const openSnackbarPopup = (msg: string, type: string) => {
    snackbarMessage.current = msg;
    snackbarType.current = type;
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (stdReceiptsSubmittedFlag) {
      setTempReceipts(stdReceiptDataAfterSubmit?.tempReceiptImages);
      setPermanentReceipts(stdReceiptDataAfterSubmit?.images);
      setEnableAttachBtn(false);
    }
  }, [stdReceiptsSubmittedFlag, stdReceiptDataAfterSubmit]);

  useEffect(() => {
    if (isStdFileUploaded) {
      setTempReceipts(stdReceiptDataAfterUpload?.tempReceiptImages);
      setPermanentReceipts(stdReceiptDataAfterUpload?.images);
      setEnableAttachBtn(false);
    }
  }, [isStdFileUploaded, stdReceiptDataAfterUpload]);

  useEffect(() => {
    setDraftId(stdDraftReceiptData?.id);
    setTempReceipts(stdDraftReceiptData?.tempReceiptImages);
    setPermanentReceipts(stdDraftReceiptData?.images);
    setEnableAttachBtn(false);
  }, [stdDraftReceiptData]);

  useEffect(() => {
    if (isErrorOnStdUpload) {
      openSnackbarPopup(stdUploadError, AppConstants.SNACKBAR.TYPES.ERROR);
      setReceiptNo("");
    }
  }, [isErrorOnStdUpload]);

  return (
    <div>
      <CustomDialog
        open={open}
        PaperProps={dialogPaperProps}
        title={getTitleContent()}
        content={getDetailsContent()}
        actions={getUploadActions()}
        handleClose={handleDialogClose}
      ></CustomDialog>
      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        onExited={handleSnackbarExited}
        autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
        message={snackbarMessage.current}
        type={snackbarType.current}
      />
      <CustomSnackbar
        open={maxSize}
        handleClose={handleSnackbarClose}
        onExited={handleSnackbarExited}
        autoHideDuration={AppConstants.SNACKBAR.AUTO_HIDE_TIMEOUT}
        message={maxSizeMessage}
        type={maxSizeSnackbarType.current}
      />
    </div>
  );
};

export default StdCodUploadReceipt;